
import {
  defineComponent,
  ref,
  reactive,
  toRefs,
  watch,
  onBeforeMount,
  onMounted,
  initCustomFormatter,
} from 'vue';
import imgBox from '@/components/page/imgBox.vue';
import imgBoxFlex from '@/components/page/imgBoxFlex.vue';
import {ElMessageBox} from 'element-plus';
import {ElMessage} from 'element-plus';
import {ElLoading} from 'element-plus';
import {useRoute, useRouter} from 'vue-router';
import axios from '@/api/http.js';

// 定义一个接口，约束imgBox的类型
interface Img {
  img: string;
  id: number;
  title?: string; //问号代表可有可无
  name?: string; //问号代表可有可无
  description?: string; //问号代表可有可无
  // id: string; 如果定义是String类型但是数据是number类型的就会提示出错
}

export default defineComponent({
  name: 'Tracks',
  components: {
    imgBox,
    imgBoxFlex
  },
  setup() {
    //============= data ==============
    const client = localStorage.getItem("eventClient");
    const route = useRoute();
    const router = useRouter();
    const id = route.query.id;
    const name = route.query.name;
    const data = reactive({
      mustWatch: [],
      sessions: [],
      contents: [
        // {
        //   img: require('../../assets/imgs/hulk.jpg'),
        //   id: 1,
        //   name: 'VMWARE TANZU',
        //   title: '2021 WMKO Intrinsic Security Solutions',
        //   description:
        //       ' Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi natus dolores aspernatur optio ad modi iure quisquam',
        // },
      ],
    });
    const data2 = reactive({
      index: 0,
      showNoResult: false,
      loading: false,
    });
    // const dataLength = data.contents.length;
    //============= method ==============
    // 无限滚动
    const load = () => {
      if (data2.showNoResult) {
        data2.loading = false;
        return
      } else {
        data2.loading = true;
        data2.index = data2.index + 1
        getData(data2.index);
      }
    };
    const goDetail = (data) => {
      router.push({
        name: 'Content',
        query: {id: data.id},
      });
    };
    const deleteItem = (id) => {
      // console.log("id:", id);
      ElMessageBox.confirm(
          'Are you sure you want to delete?  This can not be undone.',
          {
            confirmButtonText: 'Yes',
            cancelButtonText: 'Cancel',
            type: 'warning',
          }
      )
          .then(() => {
            // ElMessage({
            //   type: 'success',
            //   message: 'successfully deleted' + ' img ' + id,
            // });
          })
          .catch(() => {
            // ElMessage({
            //   type: 'info',
            //   message: 'Undeleted',
            // });
          });
    };
    // const formatData = (data) => {
    //   let arr = [];
    //   data.map(item => {
    //     let obj = {};
    //     obj['description'] = data.description;
    //     obj['title'] = data.title;
    //     obj['id'] = data.id;
    //     obj['img'] = data.img;
    //     obj['name'] = data.name;
    //     arr.push(obj);
    //   });
    //   return arr;
    // };
    const getData = (index = 0, pageSize = 10) => {
      const loading = ElLoading.service({
        lock: true,
      })
      const isPartner = route.query.isPartner;
      const pathName = route.name;
      console.log("====pathName =====", pathName)
      let obj = {};
      obj['meetingId'] = 'a7yyxczuty0qpjo2393gmyad';
      obj['index'] = index;
      obj['pageSize'] = pageSize;
      obj['isPartner'] = isPartner;
      if (pathName == 'Journeys') {
        obj['journeyId'] = route.query.id;
      } else if (pathName == 'Tracks') {
        obj['trackId'] = route.query.id;
      }
      console.log("==== isPartner=====", isPartner)
      axios.request({
        method: 'PATCH',
        url: '/virtual/filters',
        data: obj
      }).then(res => {
        loading.close();
        if (res.data.result == 200) {
          console.log("====res.data.mustWatch =====", res.data)
          data.mustWatch = res.data.data.mustWatch;
          data.sessions = res.data.data.sessions;
          // data.contents = data.contents.concat(res.data.data);
          console.log("====contents =====", data.contents)
        }
        if (res.data.result == 500) {
          data2.showNoResult = true;
          // setTimeout(()=>{
          //   data2.showNoResult = true;
          // },1000)
        }
        data2.loading = false;
      }).catch(err => {
        loading.close();
        console.log(err);
      });
    };
    getData();
    onBeforeMount(() => {

    });
    onMounted(() => {
    });
    return {
      ...toRefs(data),
      ...toRefs(data2),
      deleteItem,
      load,
      goDetail,
      id,
      name,
      getData,
      client
      // formatData,
    };
  },
});
